export const methodology = {
  text: "المنهجية",
  title1: "نهجنا",
  paragraph1:
    "في أوائل عام 2016 ، قام فريق GDHM ، جنبًا إلى جنب مع ممثلين من أكثر من 20 دولة وأكثر من 50 وكالة ومنظمة دولية ، بتصميم الإصدار 1.0 من مؤشر الصحة الرقمية العالمي (GDHI). في عام 2022 ، خضع المورد لمراجعة مؤشر ومنصة لمدة عام وعملية إعادة تصميم لمواءمة المؤشرات مع استراتيجية الصحة الرقمية العالمية لمنظمة الصحة العالمية كتكملة لأطلس الصحة الرقمية لمنظمة الصحة العالمية ويتضمن تركيزًا أكبر على الذكاء الاصطناعي والإنصاف والجنس و تغطية صحية شاملة. بالإضافة إلى ذلك ، تم تغيير الاسم إلى Global Digital Health Monitor (GDHM) للتعبير عن قيمة الأداة في توفير نظام أساسي لمراقبة تقدم الصحة الرقمية على المستوى القطري والإقليمي والعالمي. يتضمن GDHM المحدث تصورات أداء الدولة على أساس سنوي ، وتصورات إقليمية ، ومقارنات بين دولة وأخرى.",
  bulletin: {
    title: "ولتطوير المؤشرات، قام فريق GDHM بما يلي:",
    line1:
      "إجراء استعراض طبيعي للبيانات العالمية المتاحة من خلال المنظمات المتعددة الأطراف",
    line2:
      "تعيين البيانات المتاحة من خلال مراجعة الأطر والأدوات الموجودة التي طورها الخبراء لمساعدة الممارسين والبلدان على تقييم قوة ونضج البيئة التمكينية ذات الصلة بالصحة الرقمية.",
    line3:
      "عقد اجتماع لخبراء الصحة الرقمية لتطوير حالات الاستخدام لـ GDHM وصقل وتحديد مجموعة أولية من المؤشرات الموصى بها في قائمة سهلة الاستخدام وسهلة الإدارة.",
  },
  paragraph2: "قائمة مؤشرات GDHM -",
  seeHere: "انظر هنا",

  title2: "جمع البيانات والتحقق منها",
  paragraph3Start:
    "يعمل فريق GDHM مع الشركاء القطريين وممثلي وزارة الصحة الذين يقودون جهود الصحة الرقمية في بلدانهم لجمع البيانات لكل بلد. يقدم هؤلاء الشركاء القطريون بيانات بلدهم من خلال مسح سنوي لمعايير GDHM ، واختر المرحلة المناسبة لكل مؤشر وكذلك الأساس المنطقي والأدلة لدعم هذه المراحل. يتحقق فريق GDHM من البيانات المجمعة قبل نشرها. تم تضمين بيانات صحية أوسع من ",
  paragraph3Link: " مؤشرات التنمية في البنك الدولي 2019",
  paragraph3End: " لوضع سياق للنضج الصحي الرقمي",

  title3: "حساب المتوسطات والمعايير",
  paragraph4:
    "يستخدم GDHM المؤشرات الرئيسية في كل فئة لحساب المتوسط العام للبلد. ومع أنه يتم تضمين المؤشرات الفرعية لإضافة المزيد من الخصوصية لمؤشرات GDHM المحددة، إلا أنها لا تُستخدم في حساب مرحلة المكون أو البلد أو المتوسطات العالمية. ويتيح GDHM للبلدان امكانية تقييم نفسها مقارنة بالمتوسط العالمي أو مرحلة شاملة محددة.",
  title4: "البيانات المعبأة مسبقًا من المصادر المتاحة للجمهور",
  paragraph5:
    "بالنسبة للبلدان التي لم تكمل مسحًا في Global Digital Health Monitor ، تم استخراج البيانات من المعلومات المتاحة للجمهور لملء المؤشرات أدناه مسبقًا لإعطاء إحساس عام بنضج النظام البيئي الرقمي الأوسع بما يتماشى مع المؤشرات ذات الصلة. في حين أن هذه البيانات تعمل بمثابة وكيل لهذه المؤشرات الصحية الرقمية للبلدان ، فإن البيانات التي أكملها المسؤولون الحكوميون تقدم صورة أكثر دقة وقوة للتقدم في مجال الصحة الرقمية في كل بلد. نحن نشجع البلدان التي لم تقدم بيانات كاملة على طلب رابط البلد أن تفعل ذلك في أقرب وقت يناسبها.",
  indicators: {
    bulletin: {
      title:
        "المؤشرات التي استخدمت بيانات الوكيل من المصادر المتاحة للجمهور لملء البلدان التي لم تكمل المسح:",
      line1:
        "المؤشر 2 أ: إعطاء الأولوية للصحة في السياسات الوطنية للتحول الرقمي وإدارة البيانات",
      line2:
        "المؤشر 4: التنوع والإنصاف وتحليل حقوق الإنسان والتخطيط والرصد المدرجة في الاستراتيجيات والخطط الصحية الرقمية الوطنية",
      line3:
        "المؤشر 6 أ: مشاركة القطاع الخاص واستثماراته في مجال الصحة الرقمية",
      line4: "المؤشر 7: الإطار القانوني لحماية البيانات (الأمن)",
      line5:
        "المؤشر 8: قوانين أو لوائح الخصوصية والموافقة والسرية والوصول إلى المعلومات الصحية (الخصوصية)",
      line6:
        "المؤشر 9: بروتوكول لتنظيم أو اعتماد الأجهزة و / أو الخدمات الصحية - بما في ذلك أحكام الذكاء الاصطناعي والخوارزميات (في مراحل النضج الأعلى)",
      line7: "المؤشر 17: جاهزية الشبكة",
      line8:
        "المؤشر 18: التخطيط والدعم لصيانة البنية التحتية الصحية الرقمية المستمرة",
    },
    paragraph: {
      start: "وشملت مصادر البيانات ",
      govTech: "مؤشر نضج التكنولوجيا الحكومية",
      GTMI: "(GTMI)",
      NRI: "مؤشر جاهزية الشبكة",
      GCI: "مؤشر الأمن السيبراني العالمي",
      GSMA: "مؤشر اتصال المحمول GSMA",
      the: "ال",
      andThe: "و ال",
    },
  },
};
