<template>
  <div class="footer">
    <div class="footer-width">
      <span>
        <span class="ft-element sub-header contact">
          <span class="contact-info">{{ $t("footer.contactEmail") }}</span>
          <span class="website-info"
            >This website is best viewed on Desktops and Tablets and supports
            multiple languages</span
          >
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplicationFooter",
};
</script>
