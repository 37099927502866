<script>
import Vue from "vue";

export default Vue.extend({
  name: "MapPage",
});
</script>

<template>
  <div class="content-container">
    <router-view name="routecontent"></router-view>
  </div>
</template>
