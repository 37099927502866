export const date = {
  dateFormat1: "Mis à jour le: {month} {year}",
  month: {
    January: "janvier",
    February: "février",
    March: "mars",
    April: "avril",
    May: "mai",
    June: "juin",
    July: "juillet",
    August: "août",
    September: "septembre",
    October: "octobre",
    November: "novembre",
    December: "décembre",
  },
};
