export const date = {
  dateFormat1: "تحديث في: {month} {year}",
  month: {
    January: "يناير",
    February: "فبراير",
    March: "مارس",
    April: "أبريل",
    May: "مايو",
    June: "يونيو",
    July: "يوليو",
    August: "أغسطس",
    September: "سبتمبر",
    October: "اكتوبر",
    November: "نوفمبر",
    December: "ديسمبر",
  },
};
