export const date = {
  dateFormat1: "Atualizado em: {month} {year}",
  month: {
    January: "janeiro",
    February: "fevereiro",
    March: "março",
    April: "abril",
    May: "maio",
    June: "junho",
    July: "julho",
    August: "agosto",
    September: "setembro",
    October: "outubro",
    November: "novembro",
    December: "dezembro",
  },
};
